.contact-page{
    width: 100%;
    margin-top: 20px;
    .logo {
        display: block;
        padding: 8px ;
        left: 10%;
        img {
            display: block;
            margin: 20px auto;
            max-width: 100%;
            height: auto;
            transform: translate(60%, 10%);
        }
    }
    
  h1{
    color:#ffffff;
  }
    ul{
        padding: 0;
        margin: 0;
    

    li{
        padding: 0;
        margin:0;
        list-style: none;
        margin-bottom: 10px;
        overflow: hidden;
        display: block;
        position: relative;
        opacity: 0;
        animation: fadeInUp 2s 2s;
        animation-fill-mode: forwards;
        clear: both;
    }

    li.half{
        width: 49%;
        margin-left: 2%;
        float: left;
        clear: none;

        &:first-child{
            margin-left: 0;
        }
    }

    }

    input[type='text'],
    input[type='email']{
        width: 100%;
        border: 0;
        background: #B6C4B6;
        height: 50px;
        font-size: 16px;
        font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
        color: #294B29;
        padding: 0 20px;
        box-sizing: border-box;

    }

    textarea{
        width: 100%;
        border: 0;
        background: #B6C4B6;
        font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
        height: 50px;
        font-size: 16px;
        color:#294B29;
        padding: 20px;
        min-height:150px;
        box-sizing: border-box;
    }

    .flat-button{
        color: #B6C4B6;
        background: 0 0;
        font: 11px;
        letter-spacing: 3px;
        text-decoration: none;
        padding: 8px 10px;
        border: 1px solid #B6C4B6;
        font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
        float: right;
        border-radius: 4px;
    }


}



@media screen and (max-width:1200px) {
    .map-wrap{
        float: none;
        margin: 0;
        width: 100%;
        height: 400px;
    }

    .contact-page .logo{
        img{
        transform: translate(10%, -10%);
    }
}
}