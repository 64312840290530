

.home-page {
    .logo {
        display: block;
        padding: 8px ;
        left: 10%;
        img {
            display: block;
            margin: 20px auto;
            max-width: 100%;
            height: auto;
            transform: translate(60%, 10%);
        }
    }
    .text-zone {
        position: absolute;
        left: 10%;
        top: 50%;
        transform: translateY(-50%);
        width: 40%;
        max-height: 90%;
        z-index: 1;
    }
    h1 {
        color: #fff;
        font-size: 53px; 
        margin: 0;
        font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
        font-weight: 400;
        
        &::before {
            font-family: 'La Belle Aurore';
            color: #B6C4B6;
            font-size: 18px;
            position: absolute;
            margin-top: -40px;
            left: 15px;
            opacity: 0.6;
        }
        &::after {
            font-family: 'La Belle Aurore';
            color: #B6C4B6;
            font-size: 18px;
            position: absolute;
            margin-top: 18px;
            margin-left: 20px;
            animation: fadeIn 1s 1.7s backwards;
            opacity: 0.6;
        }
    }
    h2 {
        color: #8d8d8d;
        margin-top: 20px;
        font-weight: 400;
        font-size: 11px;
        font-family: sans-serif;
        letter-spacing: 3px;
        animation: fadeIn 1s 1.8s backwards;
    }
    .flat-button {
        color: #B6C4B6;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 4px;
        font-family: sans-serif;
        text-decoration: none;
        padding: 10px 18px;
        border: 1px solid #B6C4B6;
        margin-top: 25px;
        float: left;    
        font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
        animation: fadeIn 1s 1.8s backwards;
        white-space: nowrap;
        &:hover {
            background: #B6C4B6;
            color: #333;
        }
    }

   
}

@media screen and (max-width: 1200px) {
    .tags,
    .home-page .logo{
        img{
        transform: translate(10%, -10%);
    }
}
    .home-page h1{
        font-size: 40px;
    }
    .home-page h2{
        font-size: 9px;
        letter-spacing: 2px;
    }
    .home-page h1:before,
    .home-page h1:after {
      display: none;
    }
  
    .home-page .text-zone {
      position: initial;
      width: 100%;
      transform: translateY(-50%);
      padding: 10px;
      box-sizing: border-box;
      
    }
  
    .home-page .flat-button {
      float: none;
      display: block;
      margin: 20px auto 0 auto;
      width: 124px;
    }
  
    .logo-container {
      position: relative;
      width: 100px;
      height: auto;
      top: 50px;
      right: 0;
      box-sizing: border-box;
      margin: auto;
      left: 0;
    }
  
    .logo-container svg {
      position: absolute;
      top: auto;
      right: auto;
      bottom: auto;
      left: 0;
      margin: auto;
    }
  }